import React, { useState } from "react"
import CurrentDay from "./Checkout"
import AllDay from "./AllDay"
const CheckoutHome = () => {
  const [activeTab, setActiveTab] = useState("currentDay")

  const handleTabClick = tab => {
    setActiveTab(tab) 
  }

  return (
    <div className="page-content">
      <div>
        <button
          style={activeTab === "currentDay" ? styles.activeTab : styles.tab}
          onClick={() => handleTabClick("currentDay")}
        >
          Current Day
        </button>
        <button
          style={activeTab === "allDay" ? styles.activeTab : styles.tab}
          onClick={() => handleTabClick("allDay")}
        >
          Date wise
        </button>
      </div>

     
        {activeTab === "currentDay" && <CurrentDay />}
        {activeTab === "allDay" && <AllDay />}
    </div>
  )
}

const styles = {
  tabs: {
    display: "flex",
    borderBottom: "2px solid #ccc",
  },
  tab: {
    padding: "10px 20px",
    cursor: "pointer",
    border: "none",
    backgroundColor: "#f0f0f0",
    transition: "background-color 0.3s",
  },
  activeTab: {
    padding: "10px 20px",
    cursor: "pointer",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    transition: "background-color 0.3s",
  },
  //   tabContent: {
  //     marginTop: '20px',
  //   },
}

export default CheckoutHome
