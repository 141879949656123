import { GET_FEES_RECORD_ERROR, GET_FEES_RECORD_RESET, GET_FEES_RECORD_START, GET_FEES_RECORD_SUCCESS, PRINT_PAIDFEES_DETAILS, PRINT_PAIDFEES_DETAILS_FAILED, PRINT_PAIDFEES_DETAILS_SUCCESS, VIEW_PAIDFEES_DETAILS, VIEW_PAIDFEES_DETAILS_FAILED, VIEW_PAIDFEES_DETAILS_SUCCESS } from "./constants";

export const viewPaidFeesDetails = (payload) =>({
    type: VIEW_PAIDFEES_DETAILS,
    payload
})

export const viewPaidFeesDetailsSuccess = payload =>({
    type: VIEW_PAIDFEES_DETAILS_SUCCESS,
    payload
})

export const viewPaidFeesDetailsFailed = payload =>({
    type: VIEW_PAIDFEES_DETAILS_FAILED,
    payload
})

export const printPaidFeesDeatails = payload => ({
    type: PRINT_PAIDFEES_DETAILS,
    payload
})

export const printPaidFeesDeatailsSuccess = payload => ({
    type: PRINT_PAIDFEES_DETAILS_SUCCESS,
    payload
})

export const printPaidFeesDeatailsFailed = payload => ({
    type: PRINT_PAIDFEES_DETAILS_FAILED,
    payload
})


// TEACHER APP :

export const getFeesDetailsStart = payload => ({
    type : GET_FEES_RECORD_START,
    payload
})


export const getFeesDetailsSuccess = payload => ({
    type : GET_FEES_RECORD_SUCCESS,
    payload
})

export const getFeesDetailsError = payload => ({
    type : GET_FEES_RECORD_ERROR,
    payload
})

export const getFeesDetailsReset = () => ({
    type : GET_FEES_RECORD_RESET
})