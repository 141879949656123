import React, { useState, useEffect } from "react"
import { Card, Col, CardImg, Container, Input, Row, Image } from "reactstrap"
import Spinners from "components/Common/Spinner"
import axios from "axios"
import Report from "./Report"
const Checkout = () => {
  const [currentDate, setCurrentDate] = useState("")
  const [Loading, setLoading] = useState(false)
  const [date1, setDate1] = useState("")

  const [startHour, setStartHour] = useState("")
  const [startMin, setStartMin] = useState("")
  const [timePeriod, setTimePeriod] = useState("")
  const [visitor, setVisitor] = useState("")
  const [startSec, setStartSec] = useState("")
  const [checkout, setCheckout] = useState(false)
  const [TestData, setTestData] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      const timeString = new Date().toLocaleTimeString()
      const [hour, minute, second, secondWithPeriod] = timeString.split(/:| /)
      const [period] = secondWithPeriod.split(" ")

      setStartHour(hour)
      setStartMin(minute)
      setStartSec(second)
      setTimePeriod(period)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const today = new Date()
    const formattedDate = today.toISOString().split("T")[0]
    setCurrentDate(formattedDate)
  }, [])

  const baseUrl = "https://marcopolo.prisms.in/api/visitor/"
  const token = localStorage.getItem("Token").replace(/"/g, "")

  const getUserInfo = async () => {
    if (!currentDate && !date1) return

    setLoading(true)
    try {
      const dateToSend = currentDate || date1
      const response = await axios({
        method: "GET",
        url: `${baseUrl}get-checked-in-visitors`,
        headers: {
          Authorization: token,
        },
      })
      // console.log(response.data)
      setTestData(response.data.data)
    } catch (error) {
      console.error("Error fetching user info:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [currentDate, date1,checkout])

  const checkOut = async (visit_id) => {
    try {
      setCheckout(false)
      setLoading(true)
      await axios({
        method: "POST",
        url: `${baseUrl}checkout-visitor`,
        data: {
          endHour: startHour,
          endMin: startMin,
          ampmEnd: timePeriod,
          visitorId: visit_id,
        },
        headers: {
          Authorization: token,
        },
      }).then(response => {
        setCheckout(false)
        setLoading(true)
      })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="">
      <Card className="h-full p-2">
        <Row>
          <Col>
            {/* <h3 className="font-weight-bold fs-4 text-center">
                Visitor Information
              </h3> */}
            {/* <Input
                type="date"
                value={date1 || currentDate}
                onChange={e => setDate1(e.target.value)}
              /> */}
            {Loading && <Spinners />}
            <div>
              {TestData &&
                TestData.length > 0 &&
                TestData.map((item, index) => {
                  return (
                    <div key={index}>
                      <Card className="shadow-sm p-4 rounded mt-4">
                        <div className="text-center mb-3 mt-3">
                          <CardImg
                            src={item.avatar || "https://via.placeholder.com/120.png?text=No+Image"}
                            alt="Profile"
                            className="rounded-circle"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="d-flex flex-row flex-md-column">
                          <Row className="">
                            <div className="d-flex flex-row ">
                              <span
                                style={{ fontWeight: 600 }}
                                className="mt-3 w-50"
                              >
                                <i className="fa fa-user text-primary mr-2"></i>
                              </span>
                              <span className="mt-3 w-50">{item.NAME}</span>
                            </div>
                            <div className="d-flex flex-row ">
                              <span
                                style={{ fontWeight: 600 }}
                                className="mt-3 w-50"
                              >
                                <i className="fa fa-phone text-primary mr-2"></i>
                              </span>
                              <span className="mt-3 w-50">
                                {item.mobile_no}
                              </span>
                            </div>
                            <div className="d-flex flex-row ">
                              <span
                                style={{ fontWeight: 600 }}
                                className="mt-3 w-50"
                              >
                                <i className="fa fa-calendar text-primary mr-2"></i>
                              </span>
                              <span className="mt-3 w-50">{item.vDate}</span>
                            </div>

                            <div className="d-flex flex-row ">
                              <span
                                style={{ fontWeight: 600 }}
                                className="mt-3 w-50 text-primary"
                              >
                                IN
                              </span>
                              <span className="mt-3 w-50">{item.in_time}</span>
                            </div>

                            <div className="d-flex flex-row ">
                              <span
                                style={{ fontWeight: 600 }}
                                className="mt-3 w-50 text-primary"
                              >
                                OUT :
                              </span>
                              {item.out_time === "--:undefined --" || null ? (
                                <button
                                  className="btn btn-outline-danger rounded mt-2"
                                  onClick={() => checkOut(item.visitoriId)}
                                >
                                  Check out
                                </button>
                              ) : (
                                <span className="mt-3 w-50">
                                  {item.out_time}
                                </span>
                              )}
                            </div>
                          </Row>
                        </div>
                      </Card>
                    </div>
                  )
                })}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default Checkout
