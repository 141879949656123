import React, { useEffect, useState } from "react"
import { Card, CardBody, Button, Row, Col } from "reactstrap"
import moment from "moment"
import axios from "axios"

function CardComponent({ Data, acadId, sid, uid }) {
  const [cid, setCid] = useState()
  const live_Url = "https://marcopolo.prisms.in"

  const [schName, setSchoolName] = useState()

  const token = localStorage.getItem("Token").replace(/"/g, "")
  const fun_term = "showStudScolasticReportcbsecce"
  const fun_Exam = "showStudReportcbsecce"

  useEffect(() => {
    const getClassDiv = async () => {
      try {
        await axios({
          method: "POST",
          url: `${live_Url}/api/tscommon/division_id`,
          data: { acad_id: acadId },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setCid(res.data[0].classdiv_id)
        })
      } catch (error) {
        console.log(error)
      }
    }
    getClassDiv()

    const getSchoolName = async () => {
      const data = {
        fun_name: "getSchoolURL",
        sid: sid,
      }
      await axios({
        method: "POST",
        url: "https://topschool.prisms.in/rest/index.php/user_list.json",
        data,
      }).then(response => {
        setSchoolName(response.data)
      })
    }
    getSchoolName()
  }, [sid, uid])

  const handleDownloadClick = async (fun, id) => {
    try {
      const formData = new FormData()
      formData.append("task", fun)
      formData.append("option", "com_notifint")
      formData.append("view", "prestudadmission")
      formData.append("userid", uid)
      formData.append("examid", id)
      formData.append("cdid", cid)
      formData.append("acadid", acadId)
      formData.append("format", "raw")
      formData.append("sch", schName)

      const formDataObject = {}
      formData.forEach((value, key) => {
        formDataObject[key] = value
      })

      if (formDataObject) {
        if (formDataObject) {
          const queryString = new URLSearchParams(formDataObject).toString()

          const newTab = window.open(
            `https://pmapp.prisms.in/report/card/details?${queryString}`,
            "_blank"
          )

          if (newTab) {
            newTab.onload = function () {
              console.log("New tab loaded!")
            }
          } else {
            console.error("Failed to open a new tab. Please enable pop-ups.")
          }
        } else {
          console.error("HTML content not found in the response data.")
        }
      } else {
        console.error("No data received for printing.")
      }
    } catch (error) {
      console.error("Error downloading HTML:", error)
    }
  }

  return (
    <div>
      {Data.exam_details
        ? Data.exam_details.map((data, idx) => (
            <Card key={idx} className="mb-3">
              <CardBody>
                <Row className="d-flex justify-content-between align-items-center">
                  <Col>
                    <h5
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        color: "blue",
                      }}
                    >
                      {data.exam_name} - (exam wise report card)
                    </h5>
                  </Col>
                  <Col className="text-end">
                    <Button
                      color="primary"
                      onClick={() => handleDownloadClick(fun_Exam, data.id)}
                    >
                      Download
                    </Button>
                  </Col>
                </Row>
                <p
                  style={{
                    marginTop: "10px",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Published On {moment(data.created_date).format("DD-MM-YYYY")}
                </p>
              </CardBody>
            </Card>
          ))
        : null}

      {Data.term_details
        ? Data.term_details.map((data, idx) => (
            <Card key={idx} className="mb-3">
              <CardBody>
                <Row className="d-flex justify-content-between align-items-center">
                  <Col>
                    <h5
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        color: "blue",
                      }}
                    >
                      Term {data.termid} - (wise report card)
                    </h5>
                  </Col>
                </Row>
                <p
                  style={{
                    marginTop: "10px",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  Published On {moment(data.created_date).format("DD-MM-YYYY")}
                </p>
                <div className="text-end">
                  <Button
                    color="primary"
                    onClick={() => handleDownloadClick(fun_term, data.termid)}
                  >
                    Download
                  </Button>
                </div>
              </CardBody>
            </Card>
          ))
        : null}
    </div>
  )
}

export default CardComponent
