import React, { useEffect, useState, useMemo } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import Spinners from "components/Common/Spinner"
import axios from "axios"
import moment from "moment"
import "jspdf-autotable"
import "./style.css"
import { decode } from "base-64"

const Home = () => {
  const [studentReport, setStudentReport] = useState([])
  const [studentName, setStudentName] = useState("")
  const [loading, setLoading] = useState(false)
  const [isPrint, setIsPrint] = useState(false)
  let live_Url = "https://marcopolo.prisms.in"
  const columns = useMemo(
    () => [
      {
        Header: () => <div style={{ color: "#111" }}>Sr No</div>,
        accessor: "id",
        filterable: true,
        Cell: ({ row, rows }) => {
          const rowIndex = rows.indexOf(row) + 1
          return rowIndex
        },
      },
      {
        Header: () => <div style={{ color: "#111" }}>Date</div>,
        accessor: "created_date",
        filterable: true,
        Cell: ({ value }) => moment(value).format("DD-MM-YY") || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Remarks Category</div>,
        accessor: "Remakrs_cat",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Remarks</div>,
        accessor: "remarks",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Remarked By</div>,
        accessor: "Teacher",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Academic Year</div>,
        accessor: "academics",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
    ],
    []
  )
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const sid = decode(urlParams.get("z"))
  const userid = decode(urlParams.get("m"))

  const getReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/portfolio/report`,
        data: { sid: sid, stud_id: userid },
      }).then(response => {
        setLoading(false)

        setStudentReport(response.data.data)
      })
    } catch (error) {
      setLoading(false)

      console.error("Error fetching data:", error)
    }
  }

  const getStudentName = async () => {
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/tscommon/student-name`,
        data: { sid: sid, stud_id: userid },
      }).then(response => {
        setStudentName(response.data[0].student_name)
      })
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    getReport()
    getStudentName()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.print()
    }, 1000)
  }, [studentReport, studentName])

  return (
    <div className="page-content">
        <Container>
          <Card>
            <CardBody>
              <h1 className="text-center mt-2 fs-3">{studentName}</h1>

              <Row>
                <Col>
                  {loading ? (
                    <Spinners />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={studentReport}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      // handleJobClicks={handleJobClicks}
                      isJobListGlobalFilter={false}
                      isPagination={false}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={100}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
    </div>
  )
}

export default Home
