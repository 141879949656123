import React, { useEffect, useState, useMemo } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import Spinners from "components/Common/Spinner"
import axios from "axios"
import { Link } from "react-router-dom"
import moment from "moment"
import jsPDF from "jspdf"
import "jspdf-autotable"
import "./style.css"
import { encode, decode } from "base-64"
import Report from "./Report"
const Home = () => {
  const [studentReport, setStudentReport] = useState([])
  const [studentName, setStudentName] = useState("")
  const [loading, setLoading] = useState(false)
  const [isPrint, setIsPrint] = useState(false)
  let live_Url = "https://marcopolo.prisms.in";

  const sid = localStorage.getItem("sid")
  const userid = localStorage.getItem("user_id")

  const encodeSid = encode(sid.toString());
  const encodeuid = encode(userid.toString());

  const columns = useMemo(
    () => [
      {
        Header: () => <div style={{ color: "#111" }}>Sr No</div>,
        accessor: "id",
        filterable: true,
        Cell: ({ row, rows }) => {
          const rowIndex = rows.indexOf(row) + 1
          return rowIndex
        },
      },
      {
        Header: () => <div style={{ color: "#111" }}>Date</div>,
        accessor: "created_date",
        filterable: true,
        Cell: ({ value }) => moment(value).format("DD-MM-YYYY") || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Remarks Category</div>,
        accessor: "Remakrs_cat",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Remarks</div>,
        accessor: "remarks",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Remarked By</div>,
        accessor: "Teacher",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Academic Year</div>,
        accessor: "academics",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Attachment</div>,
        accessor: "attachment",
        filterable: true,
        Cell: ({ row }) => {
          const fileLocation = row.original.attachment
          return fileLocation && fileLocation !== "" ? (
            <a
              href={fileLocation}
              download
              title="Download"
              // target="_blank"
              // rel="noopener noreferrer"
            >
              <i
                className="bx bx-archive-in text-primary"
                style={{ fontSize: "18px" }}
              />
            </a>
          ) : (
            <span></span>
          )
        },
      },
    ],
    []
  )


  const getReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/portfolio/report`,
        data: { sid: sid, stud_id: userid },
      }).then(response => {
        setLoading(false)

        setStudentReport(response.data.data)
      })
    } catch (error) {
      setLoading(false)

      console.error("Error fetching data:", error)
    }
  }

  const getStudentName = async () => {
    try {
      await axios({
        method: "POST",
        url: `${live_Url}/api/tscommon/student-name`,
        data: { sid: sid, stud_id: userid },
      }).then(response => {
        setStudentName(response.data[0].student_name)
      })
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    getReport()
    getStudentName()
  }, [])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Container>
          <Card>
            <CardBody>
              <h1 className="text-center mt-2 fs-3">Portfolio Report</h1>

              <Row>
                <Row className="my-3">
                  <Col>
                    {studentName && (
                      <h1 className="text-start text-dark mt-2 fs-6">
                        {studentName}
                      </h1>
                    )}
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Link
                      className="btn btn-primary me-2 w-50"
                      onClick={() => {
                        localStorage.setItem(
                          "studentReport",
                          JSON.stringify(studentReport)
                        )
                        localStorage.setItem("studentName", studentName)
                        window.open(`https://pmapp.prisms.in/report?z=${encodeSid}&m=${encodeuid}`)
                      }}
                    >
                      Print
                    </Link>

                    {/* <button className="btn btn-success">Export to Excel</button> */}
                  </Col>
                </Row>

                <Col>
                  {loading ? (
                    <Spinners />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={studentReport}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      // handleJobClicks={handleJobClicks}
                      isJobListGlobalFilter={false}
                      isPagination={false}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={100}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination justify-content-end pagination-rounded"
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default Home
