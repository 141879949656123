import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"
import DeleteModal from "components/Common/DeleteModal"
import { useParams } from "react-router-dom"
const ResuableComponent = ({ data }) => {
  return (
    <div>
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                marginTop: "5px",
                padding: "10px",
                position: "relative",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="w-80">
                  <div className="d-flex flex-column">
                    <span className="mt-3 text-start">{item.student_name}</span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}
const Report = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"))

  const { id } = useParams()
  const getSubjectAccessReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/Assignment/view-assignment",
        data: {
          assignment_id: id,
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubjectAccessReport()
  }, [])

  return (
    <>
      <div className="page-content">
        {loading ? <Spinners /> : <ResuableComponent data={data || []} />}
      </div>
    </>
  )
}

export default Report
