import React, { useState, useEffect } from 'react'
import { Container, Form, FormGroup, Input, Label, FormFeedback, Table, Button, Spinner } from 'reactstrap'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { getAttendanceDiv, getAttendReport, getCurrentAcadid } from 'store/actions';
import { Tbody, Thead } from 'react-super-responsive-table';
import Spinners from 'components/Common/Spinner';
import { toast } from 'react-toastify';
import DeleteModal from 'components/Common/DeleteModal';

const AttendanceReport = () => {
  const dispatch = useDispatch();

  const { divisions, absendStudList, Loading, errors, successMsg } = useSelector(state => state.AttendanceReducer);

  const { acadID } = useSelector(state => state.CommonReducer);

  const [inputData, setInputData] = useState({
    batch_id: "",
    adate: "",
  })

  const handleInputChange = (inputs) => {
    setInputData({
        ...inputData,
        [inputs.target.name]: inputs.target.value
    })
}

  useEffect(() => {
    if (errors && errors !== null) {
      toast.error(errors)
    }
    if (successMsg && successMsg !== null) {
      toast.success(successMsg)
    }
  }, [errors, successMsg])

  useEffect(() => {
    if (inputData.batch_id !== "" && inputData.adate !== "") {
      dispatch(getAttendReport(inputData))
    }
  }, [inputData])


  useEffect(() => {
    dispatch(getAttendanceDiv())
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(getCurrentAcadid({
        sid: localStorage.getItem("sid")
      }))
    }

  }, [])
  return (
    <div className='page-content px-0 bg-white'>
      <Container fluid={true}>
      <h4 className='text-center mb-2'>Student Attendance Report</h4>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}>
         
          <FormGroup>
            <Label htmlFor="Selectteachers" className="form-label">
              Class Division
            </Label>
            <Input
              id="Selectteachers"
              name="batch_id"
              type="select"
              className="form-control"
              onChange={handleInputChange}
            >
              <option value={""}>
                Select Divisions
              </option>
              {
                divisions && Array.isArray(divisions) &&
                divisions.map((classDiv, index) => {
                  return (
                    <option value={classDiv.id} key={index}>
                      {classDiv.div_name}
                    </option>
                  )
                })
              }
            </Input>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="selectdate" className="form-label">
              Absent Date
            </Label>
            <Input
              id="selectdate"
              name="adate"
              type="date"
              className="form-control"
              onChange={handleInputChange}
            />
          </FormGroup>

        </Form>

        {
          Loading ?
            <div className=''>

              <Spinners />
            </div> :
            absendStudList && Array.isArray(absendStudList) && absendStudList.length > 0 &&
            <>
              <Table className='w-100 table-sm'>
                <Thead>
                  <tr>
                    <th>Roll No</th>
                    <th>Name</th>
                    <th>Division</th>
                  </tr>
                </Thead>
                <Tbody>
                  {absendStudList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.roll_no}</td>
                      <td>{data.student_name}</td>
                      <td>{data.div_name}</td>
                    </tr>
                  ))}
                </Tbody>
              </Table>
            </>
        }
      </Container>


    </div>
  )
}

export default AttendanceReport