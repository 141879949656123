import React, { useState, useEffect } from "react"
import axios from "axios"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import moment from "moment"
import { getCurrentAcadid } from "store/Common/Actions"
import { useDispatch, useSelector } from "react-redux"
import Spinners from "components/Common/Spinner"
const LeaveReport = () => {
  const [data, setData] = useState(null) // Set initial state to null, not undefined

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [leaveStatus, setLeaveStatus] = useState("Pending")

  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))

  const getLeaveTitle = async () => {
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/leave/get-leave-history",
        data: {
          leave_status: leaveStatus,
          acadId: acadID.acadid,
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [dispatch])

  useEffect(() => {
    if (leaveStatus && acadID.acadid) {
      getLeaveTitle()
    }
  }, [leaveStatus, acadID.acadid])

  const handleChange = e => {
    setLeaveStatus(e.target.value)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Container>
          <Card>
            <CardBody>
              <h1 className="text-center mt-2 mb-4 fs-4">Leave History</h1>
              <Row>
                <Col xl="4" className="mt-1">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleChange}
                  >
                    <option>Select Title</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Pending">Pending</option>
                    <option value="Cancelled">Approved</option>
                  </select>
                </Col>
              </Row>
              <div className="table-responsive mt-4">
                {loading ? (
                  <Spinners />
                ) : (
                  <Table striped bordered>
                    <thead>
                      <tr className="table-primary text-white">
                        <th>Username</th>
                        <th>Leave Type</th>
                        <th>Leave Applied</th>
                        <th>Leave Status</th>
                        <th>Leave Start</th>
                        <th>Count</th>
                        <th>Leave Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.data && data.data.length > 0 ? (
                        data.data.map(person => (
                          <tr key={person.req_id}>
                            <td>
                              {person.first_name + " " + person.last_name}
                            </td>
                            <td>{person.lname}</td>
                            <td>
                              {moment(person.create_date).format("DD-MM-YYYY")}
                            </td>
                            <td
                              className={
                                person.req_status === "Rejected"
                                  ? "text-danger fw-medium"
                                  : person.req_status === "Approved"
                                  ? "text-success fw-medium"
                                  : person.req_status === "Pending"
                                  ? "text-warning fw-medium"
                                  : ""
                              }
                            >
                              {person.req_status}
                            </td>
                            <td>
                              {moment(person.leave_start).format("DD-MM-YYYY")}
                            </td>
                            <td>{person.leave_count}</td>
                            <td>{person.reject_reason}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </div>
  )
}

export default LeaveReport
