import React from "react"
import { Card, Button, Container, Row, Col } from "reactstrap" // Import Button from reactstrap
import { Link } from "react-router-dom"
const SubjectHome = () => {
  return (
    <div className="page-content">
      <Container>
        <Card className="h-full">
        <h1 className="text-center mt-2 mb-4 fs-4">Subject Access</h1>

          <Row className="d-flex justify-content-center flex-column align-items-center justify-content-center">
            <Col xs="auto" className="mt-5">
              <Link to={"/subject-access"}>
                <Button color="primary" className="mr-2">
                  <i className="fa fa-paper-plane mr-2"></i> Submit Request
                </Button>
              </Link>
            </Col>

            <Col xs="auto" className="mt-5 mb-5">
            <Link to={"/subject-access-report"}>
            <Button color="secondary">
                <i className="fa fa-eye mr-2"></i> View Request
              </Button>
            </Link>
           
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default SubjectHome
