import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getAlbum } from 'store/actions';
import { Link } from 'react-router-dom';
import "../Dashboard/Dashboard.css"
import { useSelector } from 'react-redux';
import { replaceS3Url } from 'components/Common/ReplaceS3Url';
import moment from 'moment';
import "./Gallary.css"

const Gallery = () => {
    const { albumList } = useSelector(state => state.GallaryReducer);
    const dates = new Set();

    const [newAlbumList, setNewAlbumList] = useState([])

    const dispatch = useDispatch();


    useEffect(() => {
        if (albumList && albumList.length > 0) {
            const tempArray = {};
            const groupedAlbums = [];
            for (let i = 0; i < albumList.length; i++) {
                const album = albumList[i];
                if (!tempArray[album.id]) {
                    tempArray[album.id] =
                    {
                        id: album.id,
                        album_name: album.album_name,
                        up_date: album.up_date,
                        thumbnails: [],
                        snaps: []
                    };
                }
                tempArray[album.id].thumbnails.push(album.thumbnail);
                tempArray[album.id].snaps.push(album.snap);
                if (i === albumList.length - 1 || albumList[i + 1].id !== album.id) {
                    groupedAlbums.push(tempArray[album.id]);
                }
            }
            setNewAlbumList(groupedAlbums);
        }
        // console.log(newAlbumList)
    }, [albumList]);

    useEffect(() => {
        dispatch(getAlbum())
    }, [])
    return (
        <div className='page-content '>
            <Row className="">
                {
                    newAlbumList && Array.isArray(newAlbumList) && newAlbumList.map((data, index) => {
                        const dateNum = moment(data.up_date).format('DD/MM/YYYY');
                        return (
                            <Link
                                className="col-6 col-md-4 col-xl-3"
                                to={"/gallery/images"}
                                state={{ id: data.id }}
                                key={index}
                            >
                                <div className="p-1">
                                    <div className="row border border-2 p-1 bg-white shadow rounded-3">
                                        {
                                            data.thumbnails.map((thumb, index1) => (
                                                <div className="col-6 p-1" key={index1}>
                                                    <div className="image-wrapper">
                                                        <img
                                                            src={thumb !== '' ? thumb : replaceS3Url(data.snaps[index1]) ? replaceS3Url(data.snaps[index1]) : ""}
                                                            className="img-fluid square-image" />
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {/* 
                                        <div className="col-6 p-1">
                                        <div className="image-wrapper">
                                            <img
                                                src={data.thumbnail_1 ? data.thumbnail_1 : data.snap_1}
                                                className="img-fluid square-image" />
                                        </div>
                                        </div>
                                        <div className="col-6 p-1">
                                        <div className="image-wrapper">
                                            <img
                                                src={data.thumbnail_2 ? data.thumbnail_2 : data.snap_2}

                                                className="img-fluid square-image" />
                                        </div>
                                        </div>
                                        <div className="col-6 p-1">
                                        <div className="image-wrapper">
                                            <img
                                                src={data.thumbnail_3 ? data.thumbnail_3 : data.snap_3}

                                                className="img-fluid square-image" />
                                        </div>
                                        </div>
                                        <div className="col-6 p-1">
                                            <div className="image-wrapper">
                                                <img src={data.thumbnail_4 ? data.thumbnail_4 : data.snap_4}
                                                    className="img-fluid square-image" />
                                            </div>
                                        </div> 
                                        */}
                                    </div>
                                    <div className='text-center text-dark'>
                                        <p className=' m-0 p-0'>
                                            {data.album_name}
                                        </p>
                                        <p className='mt-0 pt-0' style={{ fontSize: "8px" }}>
                                            ({dates.has(dateNum) ? null : dateNum})
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
            </Row>
        </div>
    )
}

export default Gallery