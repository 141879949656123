import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  CardImg,
  Button,
} from "reactstrap"
import moment from "moment"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"

const Home = () => {
  const [currentDate, setCurrentDate] = useState()
  const [currentTime, setCurrentTime] = useState()
  const [visitor, setVisitor] = useState([])
  const [staff, setStaff] = useState([])
  const [userInfo, setUserInfo] = useState()

  const [startHour, setStartHour] = useState("")
  const [startMin, setStartMin] = useState("")
  const [startSec, setStartSec] = useState("")
  const [timePeriod, setTimePeriod] = useState("")
  const [Picture, setPicture] = useState()
  const [mob, setMob] = useState("")
  const [uname, setUname] = useState("")
  const [Adhar, setAdhar] = useState("")
  const [avatar, setAvatar] = useState("")

  const userName = userInfo && userInfo[0] ? userInfo[0].name : uname
  const userAdhar =
    userInfo && userInfo[0] && userInfo[0].adhar_card_no
      ? userInfo[0].adhar_card_no
      : Adhar

  const [data, setData] = useState({
    file: "",
    firstName: "",
    // middleName: "",
    // Lastname: "",
    mobileNo: "",
    adharCardNo: "",
    address: "",
    purpose: "",
    visitor_date: "",
    startHour: "",
    endHour: "",
    startMin: "",
    endMin: "",
    ampmStart: "",
    ampmEnd: "",
    visitorType: "",
    whom_to_meet_name: "",
    whom_to_meet_id: "",
  })

  useEffect(() => {
    setData(prev => ({
      ...prev,
      firstName: userName,
      adharCardNo: userAdhar,
      mobileNo: mob,
      visitor_date: moment(currentDate).format("YYYY-MM-DD"),
      startHour: startHour,
      startMin: startMin,
      ampmStart: timePeriod,
    }))
  }, [Adhar, uname, userAdhar, userName])

  const handleFileChange = e => {
    const file = e.target.files[0]

    setPicture(file)
    if (file) {
      setData(prev => ({
        ...prev,
        file: file,
      }))

      setAvatar(URL.createObjectURL(file))
    }
  }

  const image = ""
  const baseUrl = "https://marcopolo.prisms.in/api/visitor/"
  const token = JSON.parse(localStorage.getItem("Token"))

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setCurrentDate(formattedDate);
      setCurrentTime(new Date().toLocaleTimeString())

      const timeString = new Date().toLocaleTimeString()

      const [hour, minute, second, secondWithPeriod] = timeString.split(/:| /)
      const [period] = secondWithPeriod.split(" ")

      setStartHour(hour)
      setStartMin(minute)
      setStartSec(second)
      setTimePeriod(period)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const getVisitorType = async () => {
    try {
      axios({
        method: "GET",
        url: `${baseUrl}get-visitor-type`,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setVisitor(res.data.data)
      })
    } catch (error) {}
  }

  const getStaffToMeet = async () => {
    try {
      axios({
        method: "GET",
        url: `${baseUrl}get-staff-to-meet`,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setStaff(res.data.data)
      })
    } catch (error) {}
  }

  useEffect(() => {
    getVisitorType()
    getStaffToMeet()
  }, [])

  const getUserInfo = async () => {
    try {
      axios({
        method: "POST",
        url: `${baseUrl}/get-visitor_info`,
        data: {
          mobileNo: mob,
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setUserInfo(res.data.data)
      })
    } catch (error) {
      console.error("Error fetching user info:", error)
    }
  }

  useEffect(() => {
    if (mob.length === 10) {
      getUserInfo()
    }
  }, [mob])

  const handleSubmitForm = async () => {
    if (
      !data.mobileNo &&
      !data.firstName &&
      !data.adharCardNo &&
      !data.purpose
    ) {
      toast.error("Please enter all the required fields")
      return
    }
    {
      const fm = new FormData()
      fm.append("firstName", data.firstName)
      fm.append("mobileNo", data.mobileNo)
      fm.append("adharCardNo", data.adharCardNo)
      fm.append("address", data.address)
      fm.append("purpose", data.purpose)
      fm.append("visitor_date", data.visitor_date)
      fm.append("startHour", data.startHour)
      fm.append("endHour", data.endHour)
      fm.append("startMin", data.startMin)
      fm.append("ampmStart", data.ampmStart)
      fm.append("ampmEnd", data.ampmEnd)
      fm.append("entry_mode","APP")
      fm.append("visitorType", data.visitorType)
      fm.append("whom_to_meet_name", data.whom_to_meet_name)
      fm.append("whom_to_meet_id", data.whom_to_meet_id)
      fm.append("files", Picture)
      try {
        await axios({
          method: "POST",
          url: `${baseUrl}submit-visitor-form`,
          data: fm,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        }).then(res => {
          console.log(res.data.message)
          toast.success(res.data.message)
        })
      } catch (error) {}
    }
  }
  return (
    <div className="page-content">
      <Container>
        <Card className="p-4 shadow-sm">
          <Row className="d-flex justify-content-center flex-column align-items-center">
            <Col xs="auto" className="text-center">
              <div className="position-relative">
                <CardImg
                  src={
                    avatar ||
                    "https://via.placeholder.com/120.png?text=No+Image"
                  }
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "120px",
                    height: "120px",
                    objectFit: "cover",
                    border: "4px solid #007bff",
                  }}
                />

                <Input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ display: "none" }}
                  id="file-input"
                  onChange={handleFileChange}
                />

                <label
                  htmlFor="file-input"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "#007bff",
                    color: "white",
                    padding: "5px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    border: "2px solid white",
                  }}
                >
                  <i className="fa fa-camera" style={{ fontSize: "18px" }} />
                </label>
              </div>
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <InputGroup>
                <InputGroupText>
                  <i className="fa fa-phone text-primary"></i>
                </InputGroupText>
                <Input
                  type="tel"
                  maxLength={10}
                  placeholder="Enter Mobile No"
                  value={mob}
                  onChange={e => {
                    const value = e.target.value.replace(/\D/g, "")
                    if (value.length <= 10) {
                      setMob(value)
                    }
                  }}
                />
              </InputGroup>
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <InputGroup>
                <InputGroupText>
                  <i className="fa fa-user text-primary"></i>
                </InputGroupText>
                <Input
                  type="text"
                  placeholder="Enter Name"
                  value={userName}
                  onChange={e => setUname(e.target.value)}
                />
              </InputGroup>
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <InputGroup>
                <InputGroupText>
                  <i className="fa fa-poll text-primary"></i>
                </InputGroupText>
                <Input
                  type="tel"
                  maxLength={12}
                  placeholder="Enter Adhar No"
                  value={userAdhar}
                  onChange={e => {
                    const value = e.target.value.replace(/\D/g, "")
                    if (value.length <= 12) {
                      setAdhar(value)
                    }
                  }}
                />
              </InputGroup>
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <Input
                type="text"
                placeholder="Enter Visit Purpose"
                onChange={e => {
                  setData(prev => ({
                    ...prev,
                    purpose: e.target.value,
                  }))
                }}
              />
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <Input
                type="text"
                value={moment(currentDate).format("DD-MM-YYYY")}
                readOnly
              />
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <Input type="text" value={currentTime} readOnly />
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <Label>Visitor Type</Label>
              <select
                className="form-select"
                onChange={e => {
                  setData(prev => ({
                    ...prev,
                    visitorType: e.target.value,
                  }))
                }}
              >
                <option value="">Select Visitor</option>
                {visitor?.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.visitor_type}
                  </option>
                ))}
              </select>
            </Col>

            <Col xs="auto" className="mt-3 w-100">
              <Label>Whom to Meet</Label>
              <select
                className="form-select"
                onChange={e => {
                  const selectedValue = e.target.value // Get the combined value
                  const [staff_id, staff_name] = selectedValue.split(",") // Split into staff_id and name

                  // Update the state with staff_id and staff_name
                  setData(prev => ({
                    ...prev,
                    whom_to_meet_name: staff_name || "", // Set name, or empty if not selected
                    whom_to_meet_id: staff_id || "", // Set id, or empty if not selected
                  }))
                }}
              >
                <option value="">Select</option>
                {staff?.map(staff => (
                  <option
                    key={staff.staff_id}
                    value={`${staff.staff_id},${staff.name}`}
                  >
                    {staff.name}
                  </option>
                ))}
              </select>
            </Col>

            <Col className="d-flex justify-content-center align-items-center mt-3">
              <button className="btn btn-primary" onClick={handleSubmitForm}>
                Submit
              </button>
            </Col>
          </Row>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  )
}

export default Home
