import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const viewPaidFeesDetailAPI = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `${liveApiUrl}/api/parent-feesdetails/paidfeesdetails`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error While Creating Feedback:", error)
    throw error
  }
}

export const printPaidFeesDetailAPI = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `${liveApiUrl}/api/parent-feesdetails/paidfeesdetailsprint`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error While Creating Feedback:", error)
    throw error
  }
}

// FESS DETAILS FOR TEACHER -

export const PaidFeesDeatilsAPI = async payload => {
  try {
    let apiToken = JSON.parse(localStorage.getItem("Token"))
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: apiToken,
      Accept: "application/json",
      "Cache-Control": "no-cache",
    }

    const response = await axios.post(
      `${liveApiUrl}/api/FeesReport/get-fees-records`,
      payload,
      {
        headers,
      }
    )
    return response
  } catch (error) {
    console.error("Error While Creating Feedback:", error)
    throw error
  }
}
