import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import axios from "axios"
import withRouter from "components/Common/withRouter"
import "./Sidebar.css"
//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getSchoolInfo } from "store/actions"
import { useSelector } from "react-redux"

const Sidebar = props => {
  const dispatch = useDispatch()

  const { schoolInfo, Loading } = useSelector(state => state.SchoolInfoReducer)
  const [schoolName, setSchoolName] = useState()

  const getSchoolName = async () => {
    const data = {
      fun_name: "getSchoolURL",
      sid: localStorage.getItem("sid").replace(/"/g, ""),
    }
    await axios({
      method: "POST",
      url: "https://topschool.prisms.in/rest/index.php/user_list.json",
      data,
    }).then(response => {
      console.log(response.data)
      setSchoolName(response.data)
    })
  }

  useEffect(() => {
    dispatch(getSchoolInfo())
    getSchoolName()
  }, [])
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="LogoContainer">
          <img
            src={schoolInfo.school_logo}
            alt={schoolInfo.name}
            className="schoolLogo"
          />
          {/* <a href={"#"}>School url</a> */}
          <span
            className="d-block text-white"
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: schoolInfo.name }}
          />
        </div>
        <div className="text-center mt-4">
          <span
            className="text-white"
            style={{fontSize: "9px"}}
            onClick={() => window.open(`${schoolName}.prisms.in/`, "_blank")}
          >
            {schoolName}.prisms.in
          </span>
        </div>

        <div data-simplebar className="" style={{ height: "80vh" }}>
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
