import "./style.css"
import React, { useState } from "react"
import QrScanner from "react-qr-scanner"
import { toast, ToastContainer } from "react-toastify"
import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import axios from "axios"
const QRScanner = () => {
  const [scanResult, setScanResult] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  let baseUrl = "https://marcopolo.prisms.in/api/visitor/QR-scan-visitor"
  const token = localStorage.getItem("Token").replace(/"/g, "")

  function fixAndParseJSON(rawData) {
    let fixedData = rawData
      .replace(/(\w+):/g, '"$1":')
      .replace(/:\s*([^",\}\]]+)/g, function (match, value) {
        if (/^\d+(\.\d+)?$/.test(value)) {
          return `:${value}`
        } else if (value === "true" || value === "false" || value === "null") {
          return `:${value}`
        } else {
          return `:"${value.replace(/"/g, '\\"')}"`
        }
      })

    try {
      let parsedData = JSON.parse(fixedData)
      setScanResult(parsedData)
      return parsedData
    } catch (error) {
      console.error("Error parsing JSON:", error)
      return null
    }
  }

  const handleScan = data => {
    if (data) {
      fixAndParseJSON(data.text)
      setModalOpen(true)
    }
  }

  const handleError = err => {
    console.error("QR Scan Error:", err)
    alert("An error occurred while scanning the QR code. Please try again.")
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleFormSubmit = async e => {
    e.preventDefault()

    if (!scanResult || scanResult.length === 0) {
      console.error("No data available in scanResult")
      return
    }

    console.log(scanResult)

    const data = {
      encryptedUserId: scanResult[0].userid,
      visit_id: `'${scanResult[0].visit_id}'`,
      type: scanResult[0].type,
      date: scanResult[0].Date,
      visit_purpose: scanResult[0].Visit_purpose,
    }

    console.log(data)

    try {
      const response = await axios({
        method: "POST",
        url: baseUrl,
        data: data,
        headers: {
          Authorization: token,
        },
      })

      console.log(response.data)

      setModalOpen(false)
    } catch (error) {
      console.error("Error during form submission:", error)
    }
  }

  return (
    <div className="">
      <Container>
        <Row className="r">
          <Col xs={12} sm={8} md={6} lg={5}>
            <Card className="shadow p-4 mt-4">
              <CardBody>
                <h1 className="text-center fs-5">Scan QR</h1>
                <QrScanner
                  delay={300}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  onError={handleError}
                  onScan={handleScan}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={modalOpen} toggle={handleModalClose} size="lg">
        <ModalHeader toggle={handleModalClose}>
          Submit Scanned Result
        </ModalHeader>
        <ModalBody>
          {scanResult && scanResult.length > 0 ? (
            <div className="mt-4">
              <p>
                <strong>Type:</strong> {scanResult[0].type || "N/A"}
              </p>
              <p>
                <strong>Date:</strong> {scanResult[0].Date || "N/A"}
              </p>
              <p>
                <strong>Visit Purpose:</strong>{" "}
                {scanResult[0].Visit_purpose || "N/A"}
              </p>
            </div>
          ) : (
            <p>No data available</p>
          )}

          <Form onSubmit={handleFormSubmit}>
            <ModalFooter>
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button color="danger" onClick={handleModalClose}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default QRScanner
