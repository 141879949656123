import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects"
import {
  PRINT_PAIDFEES_DETAILS,
  VIEW_PAIDFEES_DETAILS,
  GET_FEES_RECORD_ERROR,
  GET_FEES_RECORD_START,
  GET_FEES_RECORD_SUCCESS,
} from "./constants"
import {
  printPaidFeesDetailAPI,
  viewPaidFeesDetailAPI,
  PaidFeesDeatilsAPI,
} from "./api"
import {
  printPaidFeesDeatailsFailed,
  printPaidFeesDeatailsSuccess,
  viewPaidFeesDetailsFailed,
  viewPaidFeesDetailsSuccess,
  getFeesDetailsSuccess,
  getFeesDetailsError,
} from "./action"

function* viewPaidFeesDetailsAsync(action) {
  try {
    const response = yield call(viewPaidFeesDetailAPI, action.payload)
    if (response) {
      yield put(viewPaidFeesDetailsSuccess(response?.data || {}))
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(
      viewPaidFeesDetailsFailed(
        error?.response?.message || "Unable to view paid fees details"
      )
    )
  }
}

function* printPaidFeesDetailsAsync(action) {
  try {
    const response = yield call(printPaidFeesDetailAPI, action.payload)
    if (response) {
      yield put(printPaidFeesDeatailsSuccess(response?.data || {}))
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(
      printPaidFeesDeatailsFailed(
        error?.response?.message || "Unable to view paid fees details"
      )
    )
  }
}

function* FeesDetailsAsync(action) {
  try {
    const response = yield call(PaidFeesDeatilsAPI, action.payload)
    if (response) {
      yield put(getFeesDetailsSuccess(response?.data || {}))
    } else {
      throw response
    }
  } catch (error) {
    console.log("error", error)
    yield put(
      getFeesDetailsError(
        error?.response?.message || "Unable to view paid fees details"
      )
    )
  }
}
function* FeesSaga() {
  yield takeEvery(VIEW_PAIDFEES_DETAILS, viewPaidFeesDetailsAsync)
  yield takeEvery(PRINT_PAIDFEES_DETAILS, printPaidFeesDetailsAsync)
  yield takeEvery(GET_FEES_RECORD_START, FeesDetailsAsync)
  //yield takeLatest(LOGOUT_SU_USER, logoutUser)
}

export default FeesSaga
