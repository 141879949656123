import React, { useState, useEffect } from "react"
import axios from "axios"
import moment from "moment"

const LeaveReport = () => {

  const data = {
    sid: localStorage.getItem("sid"),
    user_id: localStorage.getItem("uid"),
  }
  
  const [report, setReport] = useState([])
  const [apiLoader, setApiLoader] = useState(false)

  const getReport = async () => {
    setApiLoader(true)
    try {
      const token = "#$laksdfnlknoea#$@$%^&%$" 
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/studentLeave/report",
        data,
        headers: {
          Authorization: `${token}`,
        },
      })

      setReport(response.data)
      setApiLoader(false)
    } catch (error) {
      console.error(error)
      setApiLoader(false)
    }
  }

  useEffect(() => {
    getReport()
  }, [])

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Leave Report</h2>

      {apiLoader && (
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      {!apiLoader && report.length === 0 && (
        <div className="alert alert-info" role="alert">
          No leave data available.
        </div>
      )}

      <div className="row">
        {report.map((data, index) => {
          const {
            leave_applied_date,
            leave_start_date,
            leave_end_date,
            leave_reason,
            leave_attachment_path,
            halfDay,
          } = data

          return (
            <div className="col-12 mb-4" key={index}>
              <div className="card p-3 shadow-sm">
                <div className="row">
                  <div className="col-md-4">
                    <h5>Applied Date</h5>
                    <p>{moment(leave_applied_date).format("DD-MM-YY")}</p>
                  </div>
                  <div className="col-md-4">
                    <h5>Start Date</h5>
                    <p>{moment(leave_start_date).format("DD-MM-YY")}</p>
                  </div>
                  <div className="col-md-4">
                    <h5>End Date</h5>
                    <p>{moment(leave_end_date).format("DD-MM-YY")}</p>
                  </div>
                </div>

                <div className="mt-3">
                  <h5>Reason for Leave</h5>
                  <p>{leave_reason ? leave_reason : "-"}</p>
                  {halfDay === 1 && (
                    <p>
                      <strong>Half Day Leave</strong>
                    </p>
                  )}
                  {leave_attachment_path && (
                    <div>
                      <a
                        // variant="link"
                        href={leave_attachment_path}
                      >
                        Download Attachment
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LeaveReport
