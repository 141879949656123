import React from "react"
import { Container, Row, Col, Button, Card } from "reactstrap"
import { Link } from "react-router-dom"
const Home = () => {
  const handleClick = () => {
    //https://pmapp.prisms.in/dashboard
    window.open("https://pmapp.prisms.in/visitor-scanner", "_blank")
  }

  return (
    <div className="">
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh", backgroundColor:"#fff" }}
      >
        <Card
          className="h-auto w-100 p-4 shadow-lg rounded-lg"
          style={{ maxWidth: "400px" }}
        >
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs="auto" className="mb-4">
              <div className="icon-container mb-3 d-flex justify-content-center">
                <i
                  className="bx bx-money"
                  style={{ fontSize: "40px", color: "#007bff" }}
                />
              </div>
              <button
                className="btn btn-primary w-100 py-3 rounded-pill shadow-sm"
                onClick={handleClick}
                style={{
                  fontSize: "16px",
                  background: "#007bff",
                  border: "none",
                }}
              >
                Scan QR Code
              </button>
            </Col>

            <Col xs="auto" className="mb-4">
              <div className="icon-container mb-3 d-flex justify-content-center">
                <i
                  className="bx bx-id-card"
                  style={{ fontSize: "40px", color: "#28a745" }}
                />
              </div>
              <Link to="/visitor-manually" className="w-100">
                <button
                  className="btn btn-success w-100 py-3 rounded-pill shadow-sm"
                  style={{ fontSize: "16px", border: "none" }}
                >
                  Enter Manually
                </button>
              </Link>
            </Col>

            <Col xs="auto" className="mb-4">
              <div className="icon-container mb-3 d-flex justify-content-center">
                <i
                  className="bx bx-log-out"
                  style={{ fontSize: "40px", color: "#dc3545" }}
                />
              </div>
              <Link to="/visitors" className="w-100">
                <button
                  className="btn btn-danger w-100 py-3 rounded-pill shadow-sm"
                  style={{ fontSize: "16px", border: "none" }}
                >
                  Checkout
                </button>
              </Link>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default Home
