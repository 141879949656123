import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";

const ReportCard = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const task = urlParams.get("task");
    const option = urlParams.get("option");
    const view = urlParams.get("view");
    const userid = urlParams.get("userid");
    const examid = urlParams.get("examid");
    const cdid = urlParams.get("cdid");
    const acadid = urlParams.get("acadid");
    const format = urlParams.get("format");
    const sch = urlParams.get("sch");

    const getResult = async () => {
      setLoading(true);
      setError(null);
      try {
        const formData = new FormData();
        formData.append("task", task);
        formData.append("option", option);
        formData.append("view", view);
        formData.append("userid", userid);
        formData.append("examid", examid);
        formData.append("cdid", cdid);
        formData.append("acadid", acadid);
        formData.append("format", format);

        const response = await axios.post(
          `https://${sch}.prisms.in/index.php`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (typeof response.data === "string") {
          setHtmlContent(response.data);
        } else if (response.data.content) {
          setHtmlContent(response.data.content);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("Failed to fetch report. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    getResult();
  }, []);

  const printContent = () => {
    if (htmlContent) {
      const printWindow = window.open("", "_blank", "width=1000,height=1000");

      if (printWindow) {
        printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                }
              </style>
            </head>
            <body>
              <div>${htmlContent}</div>
            </body>
          </html>
        `);

        printWindow.document.close();
        printWindow.print();
      } else {
        console.error("Failed to open the print window. Please allow pop-ups.");
      }
    } else {
      console.error("No content available to print.");
    }
  };

  useEffect(() => {
    if (htmlContent) {
      const timer = setTimeout(() => {
        printContent();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [htmlContent]);

  const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);

  return (
    <div className="page-content">
      {loading && <div className="loading">Loading...</div>}
      {error && <div className="error">{error}</div>}
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}></div>
    </div>
  );
};

export default ReportCard;
