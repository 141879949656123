import React, { useEffect, useState } from "react"
import axios from "axios"
import { Card, CardBody, Container, Row, Col, Alert } from "reactstrap"
import Spinners from "components/Common/Spinner"
import CardComponent from "./List"
const Listview = () => {
  const live_Url = "https://marcopolo.prisms.in"

  // const { loading, Data } = useSelector(state => state.examModule)
  const [loading, setLoading] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState()
  const [dataAc, setDataAc] = useState([])
  const [Data, setData] = useState([])

  const [currentAcad, setCurrentAcad] = useState()
  const [currentAcadId, setCurrentAcadId] = useState()

  const sid = localStorage.getItem("sid").replace(/"/g, "")
  const uid = localStorage.getItem("user_id")
  useEffect(() => {
    const getAcadYear = async () => {
      try {
        await axios({
          method: "POST",
          url: `${live_Url}/api/tscommon/acad/year`,
          data: {
            sid: sid,
          },
        }).then(response => {
          console.log("e")
          console.log(response.data)
          setDataAc(response.data)
        })
      } catch (error) {
        console.log(error)
      }
    }
    getAcadYear()

    const getAcadId = async () => {
      try {
        await axios({
          method: "POST",
          url: `${live_Url}/api/tscommon/getCurrentAcad`,
          data: { sid: sid },
        }).then(response => {
          setCurrentAcad(response.data[0].academics)
          setCurrentAcadId(response.data[0].acadid)
          setSelectedOptions(response.data[0].acadid)
        })
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    getAcadId()
  }, [sid])

  const onHandleChange = e => {
    setSelectedOptions(e.target.value)
  }

  useEffect(() => {
    const data = {
      fun_name: "getExamNamesDetails",
      sid: sid,
      u_id: uid,
      acadId: selectedOptions ? selectedOptions : currentAcadId,
    }

    const getExamStart = async () => {
      try {
        await axios({
          method: "POST",
          url: "https://topschool.prisms.in/rest/index.php/user_list.json?",
          data: data,
        })
          .then(response => {
            console.log(response.data)
            setData(response.data)
          })
          .catch(error => {
            console.error(error)
          })
      } catch (error) {}
    }
    if (data) {
      getExamStart(data)
    }
  }, [sid, uid, selectedOptions])

  return (
    <div className="page-content">
      <Container>
        <Row className="justify-content-center">
          <Col xl="4" className="mt-1">
            <select
              className="form-select"
              aria-label="Default select example"
              value={selectedOptions || currentAcadId}
              onChange={onHandleChange}
            >
              {dataAc ? (
                dataAc.map((item, index) => (
                  <option key={index} value={item.acadid}>
                    {item.academics ? item.academics : currentAcad}
                  </option>
                ))
              ) : (
                <option value="Test"></option>
              )}
            </select>
            {loading ? <Spinners /> : null}

            {Data ? (
              <Card className="mt-4">
                <CardBody>
                  <CardComponent
                    Data={Data}
                    loading={loading}
                    acadId={selectedOptions}
                    sid={sid}
                    uid={uid}
                  />
                </CardBody>
              </Card>
            ) : (
              <Alert
                color="info"
                className="text-center mt-4"
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "xl",
                }}
              >
                Report not available
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Listview
