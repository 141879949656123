import axios from "axios"

const liveApiUrl = process.env.REACT_APP_LIVE_API_URL

export const getAttendanceDivAPI = async() => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))       
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.get(`${liveApiUrl}/api/teacher-attendanceCenter/divisions`, {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Dashboard Icons:", error)
        throw error
    }
}


export const getStudListAPI = async(payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))       
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.post(`${liveApiUrl}/api/teacher-attendanceCenter/studlist`, payload , {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Dashboard Icons:", error)
        throw error
    }
}


export const markAbsentStudAPI = async(payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))       
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.post(`${liveApiUrl}/api/teacher-attendanceCenter/uploadAttendance`, payload , {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Dashboard Icons:", error)
        throw error
    }
}


export const getAttendReportApi = async(payload) => {
    try {

        let apiToken = JSON.parse(localStorage.getItem("Token"))       
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: apiToken,
            Accept: "application/json",
            "Cache-Control": "no-cache",
        }

        const response = await axios.post(`${liveApiUrl}/api/teacher-attendanceCenter/getStudentDailyAttendance`, payload , {
            headers,
        })
        return response
    } catch (error) {
        console.error("Error Gettting Dashboard Icons:", error)
        throw error
    }
}