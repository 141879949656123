import React, { useEffect, useState, useMemo } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid } from "store/Common/Actions"
import { getFeesDetailsStart, getFeesDetailsReset } from "store/actions"

const Home = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { Loading, TeacherFeesReport } = useSelector(state => state.FeesReducer)
  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  let baseUrl = "https://marcopolo.prisms.in/api/examfillmarks"
  const [loading, setLoading] = useState(false)

  const [academic, setAcademic] = useState([])
  const [div, setDiv] = useState([])
  const [div_id, setDiv_id] = useState("")

  const columns = useMemo(
    () => [
      {
        Header: () => <div style={{ color: "#111" }}>Student Name</div>,
        accessor: "name",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Total Fees</div>,
        accessor: "total_amount",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Total Paid</div>,
        accessor: "paid_amount",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>Total Due</div>,
        accessor: "Due_Amount",
        filterable: true,
        Cell: ({ value }) => value || "",
      },
      {
        Header: () => <div style={{ color: "#111" }}>View</div>,
        filterable: true,
        accessor: "user_id",
        Cell: ({ row }) => {
          return (
            <Link to={`/fees/details/${row.original.user_id}/${row.original.package_id}`}>
              <i
                className="fa fa-eye text-primary"
                style={{ fontSize: "18px" }}
              />
            </Link>
          )
        },
      },
    ],
    []
  )

  // get-academic-year

  const getAcademicYear = async () => {
    try {
      await axios({
        method: "GET",
        url: `${baseUrl}/get-Academic-year`,
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setAcademic(res.data.data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
    // dispatch(getFeesDetailsReset())
    getAcademicYear()
  }, [])

  useEffect(() => {
    const getClassDiv = async () => {
      try {
        await axios({
          method: "POST",
          url: `${baseUrl}/get-classdiv-list`,
          data: {
            acad_id: acadID.acadid,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setDiv(res.data.data)
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (acadID.acadid) {
      getClassDiv()
    }
  }, [acadID.acadid])

  useEffect(() => {
    if (div_id) {
      dispatch(
        getFeesDetailsStart({
          classdiv_id: div_id,
        })
      )
    }
  }, [div_id])

  // useEffect(() => {
  //   navigate(-1)
  //   dispatch(getFeesDetailsReset())
  // }, [])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Container>
          <CardBody>
            <h1 className="text-center mt-2 fs-4">Fees Report</h1>
            <Row>
              <Col xl="12" className="mt-2">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={e => setDiv_id(e.target.value)}
                >
                  <option>Select Class</option>
                  {div.length > 0 &&
                    div.map((item, index) => (
                      <option key={item.id} value={item.id}>
                        {item.div_name}
                      </option>
                    ))}
                </select>
              </Col>
            </Row>

            {loading ? (
              <Spinners />
            ) : (
              <TableContainer
                columns={columns}
                data={
                  TeacherFeesReport.students ? TeacherFeesReport.students : []
                }
                isGlobalFilter={false}
                Loading={Loading}
                isAddOptions={false}
                // handleJobClicks={handleJobClicks}
                isJobListGlobalFilter={false}
                isPagination={false}
                iscustomPageSizeOptions={false}
                isShowingPageLength={false}
                customPageSize={100}
                tableClass="table-bordered align-middle nowrap mt-2"
                paginationDiv="col-sm-12 col-md-7"
                pagination="pagination justify-content-end pagination-rounded"
              />
            )}
          </CardBody>
          {/* </Card> */}
        </Container>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Home
