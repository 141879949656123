import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Spinner,
} from "reactstrap"
import moment from "moment"
import axios from "axios"
import TableContainer from "components/Common/TableContainer"

const LeaveReport = () => {
  const sid = localStorage.getItem("sid").replace(/"/g, "")
  const userid = localStorage.getItem("user_id")

  const data = {
    sid: sid,
    user_id: userid,
  }
  const [report, setReport] = useState([])
  const [apiLoader, setApiLoader] = useState(false)

  const getReport = async () => {
    setApiLoader(true)
    try {
      const response = await axios({
        method: "post",
        url: "https://marcopolo.prisms.in/api/studentLeave/report",
        data,
      })

      setTimeout(() => {
        setReport(response.data)
        setApiLoader(false)
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getReport()
  }, [])
  return (
    <>
      <div className="page-content">
        <Container>
          <Card>
            <CardBody>
              <h1 className="text-center mt-2 mb-4 fs-5">Leave Report</h1>

              {apiLoader ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                <div>
                  {report.map((data, index) => {
                    const {
                      leave_applied_date,
                      leave_start_date,
                      leave_end_date,
                      leave_reason,
                      leave_attachment_path,
                      halfDay,
                    } = data

                    return (
                      <Card key={index} className="mb-3">
                        <CardBody>
                          <div
                            key={index}
                          
                          >
                            <div className="d-flex justify-content-between">
                              <div className="w-40">
                                <div className="d-flex flex-column">
                                  <span className="mt-1 text-start fs-6 text-primary fw-bold">
                                    Applied Date
                                  </span>
                                  <span className="mt-1 text-start fs-6 text-success fw-bold">
                                    Start Date{" "}
                                  </span>
                                  <span className="mt-1 text-start fs-6 text-danger fw-bold">
                                    End Date
                                  </span>
                                  <span className="mt-1 text-start fs-6 text-dark fw-bold">
                                    Reason for Leave
                                  </span>
                                  {halfDay ? (
                                    <span className="mt-1 text-start fs-6 text-dark fw-bold">
                                      Leave Type
                                    </span>
                                  ) : null}

                                  {leave_attachment_path ? (
                                    <span className="mt-1 text-start fs-6 text-dark fw-bold">
                                      Attachment
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="w-60">
                                <div className="d-flex flex-column">
                                  <span className="mt-1 text-start fs-6 text-dark">
                                    {moment(leave_applied_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                  <span className="mt-1 text-start fs-6 text-dark">
                                    {moment(leave_start_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                  <span className="mt-1 text-start fs-6 text-dark">
                                    {moment(leave_end_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                  <span className="mt-1 text-start fs-6 text-dark">
                                    {leave_reason}
                                  </span>
                                  {halfDay ? (
                                    <span className="mt-1 text-start fs-6 text-dark fw-bold">
                                      Half Day
                                    </span>
                                  ) : null}
                                  {leave_attachment_path ? (
                                                                        <span className="mt-1 text-start fs-6 text-dark fw-bold">

                                    <a
                                      href={leave_attachment_path}
                                      download
                                      title="Download Assignment"
                                    >
                                      <i
                                        className="bx bx-archive-in"
                                        style={{ fontSize: "18px" }}
                                      />
                                    </a>
                                    </span>

                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    )
                  })}
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default LeaveReport
