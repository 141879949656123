import React, { useEffect, useState, useMemo } from "react"
import { Col, Row, Label, Badge, Card, Image, CardImg, Input } from "reactstrap"
import Spinners from "components/Common/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid } from "store/Common/Actions"
import { toast, ToastContainer } from "react-toastify"
import axios from "axios"
const Home = () => {
  const dispatch = useDispatch()
  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  const [div, setDiv] = useState([])
  const [div_id, setDiv_id] = useState("")

  const [Uid, setUid_id] = useState(null)
  const [student, setStudent] = useState([])

  const [upload, setUpload] = useState(false)
  const [file, setFile] = useState()
  const [Events, setEvents] = useState(false)
  let baseUrl = "https://marcopolo.prisms.in/api/examfillmarks"

  useEffect(() => {
    const getClassDiv = async () => {
      try {
        await axios({
          method: "POST",
          url: `${baseUrl}/get-classdiv-list`,
          data: {
            acad_id: acadID.acadid,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setDiv(res.data.data)
        })
      } catch (error) {
        console.log(error)
      }
    }
    if (acadID.acadid) {
      getClassDiv()
    }
  }, [acadID.acadid])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [])

  useEffect(() => {
    const getstudentdetails = async () => {
      try {
        setEvents(true)
        await axios({
          method: "POST",
          url: "https://marcopolo.prisms.in/api/tscommon/getstudentdetails",
          data: {
            sid: localStorage.getItem("sid"),
            division: div_id,
          },
          headers: {
            Authorization: token,
          },
        }).then(res => {
          setStudent(res.data)
          setEvents(false)
        })
      } catch (error) {
        setEvents(false)
      }
    }
    if (div_id) {
      getstudentdetails()
    }
  }, [div_id, upload])

  const handleSortChange = e => {
    const selectedSortOption = e.target.value

    let sortedArray
    switch (selectedSortOption) {
      case "First Name":
        sortedArray = [...student].sort((a, b) =>
          a.fname.localeCompare(b.fname)
        )
        break
      // case "Last Name":
      //   sortedArray = [...student].sort((a, b) =>
      //     a.lname.localeCompare(b.lname)
      //   )
      //   break
      case "Roll No":
        sortedArray = [...student].sort((a, b) => a.user_id - b.user_id)
        break
      case "Gender":
        sortedArray = studentData
        break
      default:
        sortedArray = studentData
        break
    }

    setStudent(sortedArray)
  }

  const handleFileChange = (e, user_id) => {
    const file = e.target.files[0]
    if (!file) return

    setFile(file)
    setUid_id(user_id)

    const avatarUrl = URL.createObjectURL(file)

    setStudent(prevData =>
      prevData.map(student =>
        student.user_id === user_id
          ? { ...student, avatar: avatarUrl }
          : student
      )
    )
  }

  const UploadImage = async () => {
    try {
      setEvents(true)
      setUpload(false)
      const formData = new FormData()

      formData.append("student_id", Uid)
      formData.append("files", file)

      const response = await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/StudentProfilePic/upload-picture",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })

      toast.success(response.data.message)
      setEvents(false)
      setUid_id(null)
      setUpload(true)
    } catch (error) {
      console.log(error)
      setEvents(false)
      setUpload(false)
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <h1 className="text-center mt-2 fs-4">Student Profile Pic</h1>

        <Row>
          {/* First Select Dropdown */}
          <Col xl="6" md="6" sm="3" className="mt-2">
            <select
              className="form-select"
              aria-label="Select Class"
              onChange={e => setDiv_id(e.target.value)}
            >
              <option>Select Class</option>
              {div.length > 0 &&
                div.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.div_name}
                  </option>
                ))}
            </select>
          </Col>

          {/* Second Select Dropdown */}
          <Col xl="6" md="6" sm="3" className="mt-4 mb-3">
            <select
              className="form-select"
              aria-label="Select Class"
              onChange={handleSortChange}
            >
              <option>Select Sort Order</option>
              <option>First Name</option>
              <option>Roll No</option>
              <option>Gender</option>
            </select>
          </Col>
        </Row>
        {Events ? (
          <Spinners />
        ) : (
          <Col xl="6" md="6" sm="12" className="mt-2">
            {student &&
              student.map((item, idx) => (
                <Card key={idx} className="p-4">
                  <CardImg
                    src={item.avatar || "https://via.placeholder.com/100"}
                    alt="Profile"
                    className="mb-3"
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />

                  <Input
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    style={{ display: "none" }}
                    id={`file-input-${item.user_id}`}
                    onChange={e => handleFileChange(e, item.user_id)}
                  />

                  <label
                    htmlFor={`file-input-${item.user_id}`}
                    style={{
                      position: "absolute",
                      bottom: 55,
                      right: 5,
                      backgroundColor: "#007bff",
                      color: "white",
                      padding: "8px",
                      borderRadius: "50%",
                      cursor: "pointer",
                      border: "2px solid white",
                    }}
                  >
                    <i className="fa fa-camera" style={{ fontSize: "18px" }} />
                  </label>

                  <span className="fw-bold">{`${item.fname} ${item.lname}`}</span>
                  {item.user_id === Uid ? (
                    <button
                      className="btn btn-outline-primary rounded mt-4"
                      onClick={UploadImage}
                    >
                      Upload
                    </button>
                  ) : null}
                </Card>
              ))}
          </Col>
        )}
      </div>
      <ToastContainer />
    </div>
  )
}

export default Home
