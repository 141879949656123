import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"
import { Link } from "react-router-dom"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

const ResuableComponent = ({ data, setDeleted }) => {
  const token = JSON.parse(localStorage.getItem("Token"))
  const [modalOpen, setModalOpen] = useState(false)
  const [assignmentId, setAssignmentId] = useState(null)
  const toggleModal = () => setModalOpen(!modalOpen)

  const deleteAssignment = async () => {
    setDeleted(false)
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/Assignment/delete-assignment",
        data: {
          assignment_id: assignmentId,
        },
        headers: {
          Authorization: token,
        },
      })
      setModalOpen(false)
      setDeleted(true)
    } catch (error) {
      console.log(error)
      setDeleted(false)
    }
  }

  const handleDeleteClick = id => {
    setAssignmentId(id)
    toggleModal()
  }
  return (
    <div>
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                marginTop: "5px",
                padding: "10px",
                position: "relative",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="w-80">
                  <div className="d-flex flex-column">
                    <span className="mt-3 text-start">
                      {item.assignmentName}
                    </span>
                    <span className="mt-1 text-start">
                      Description: {item.description}
                    </span>
                  </div>
                </div>
                <div className="w-30 mt-4 text-center">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <span className="mt-3 mx-1">
                      <a
                        href={item.file_location}
                        download
                        title="Download Assignment"
                      >
                        <i
                          className="bx bx-archive-in"
                          style={{ fontSize: "18px" }}
                        />
                      </a>
                    </span>

                    <span
                      className="mt-3 mx-2"
                      onClick={() => handleDeleteClick(item.id)}
                    >
                      <i
                        className="bx bxs-trash text-danger"
                        style={{ fontSize: "18px" }}
                      />
                    </span>

                    <span className="mt-3">
                      <Link to={`/view-assignment/${item.id}`}>
                        <i
                          className="fa fa-eye text-dark"
                          style={{ fontSize: "18px" }}
                        />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
        <ModalBody>Are you sure you want to delete this assignment?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            No
          </Button>
          <Button color="danger" onClick={deleteAssignment}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const Report = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"))
  const [isDeleted, setDeleted] = useState(false)

  const getSubjectAccessReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "GET",
        url: "https://marcopolo.prisms.in/api/Assignment/get-all-assignments",

        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubjectAccessReport()
  }, [isDeleted])

  return (
    <>
      <div className="page-content">
        <h1 className="text-center mt-2 mb-4 fs-4">Assignment Report</h1>

        {loading ? (
          <Spinners />
        ) : (
          <ResuableComponent data={data || []} setDeleted={setDeleted} />
        )}
      </div>
    </>
  )
}

export default Report
