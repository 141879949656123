import React, { useEffect, useState, useMemo } from "react"
import { Col, Row, Label, Badge, Card } from "reactstrap"
import Spinners from "components/Common/Spinner"
import axios from "axios"
import { Multiselect } from "multiselect-react-dropdown"
const Home = () => {
  const token = JSON.parse(localStorage.getItem("Token"))
  const [selectedEvents, setSelectedEvents] = useState([]);

  const [Events, setEvents] = useState([])
  const baseUrl = "https://marcopolo.prisms.in/api/teacher-calendar/calendars"

  const getEvents = async () => {
    try {
      await axios({
        method: "GET",
        url: baseUrl,
        headers: {
          Authorization: token,
        },
      }).then(response => {
        console.log(response.data)
        setEvents(response.data.data)
      })
    } catch (error) {}
  }

  useEffect(() => {
    getEvents()
  }, [])

  const options = Events.map(event => ({
    value: event.id,
    key: event.title,
    color_code: event.color_code,
  }))

  const handleSelect = selectedList => {
    setSelectedEvents(selectedList)
    console.log("Selected events:", selectedList)
  }

  const handleRemove = selectedList => {
    setSelectedEvents(selectedList)
    console.log("Remaining events after removal:", selectedList)
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Col xl="12" className="mt-1">
          <Multiselect
            options={options}
            displayValue="key"
            onSelect={handleSelect}
            onRemove={handleRemove}
            showCheckbox={true}
            selectedValues={selectedEvents}
          />
        </Col>
      </div>
    </div>
  )
}

export default Home

// <Multiselect
//   displayValue="key"
//   onKeyPressFn={function noRefCheck(){}}
//   onRemove={function noRefCheck(){}}
//   onSearch={function noRefCheck(){}}
//   onSelect={function noRefCheck(){}}
//   options={options}
//   showCheckbox
// />
