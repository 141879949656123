import React, { useState, useEffect } from "react"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify"
import { getCurrentAcadid } from "store/Common/Actions"
import { useDispatch, useSelector } from "react-redux"

import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap"
import Spinners from "components/Common/Spinner"
const ApplyLeave = () => {
  const dispatch = useDispatch()
  const { acadID } = useSelector(state => state.CommonReducer)
  const token = JSON.parse(localStorage.getItem("Token"))
  const [title, setTitle] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentDate, setCurrentDate] = useState("");
  const [leaveCount, setLeaveCount] = useState()

  const [data, setData] = useState({
    leaveType: "",
    acadText: "",
    startLeave: "",
    endLeave: "",
    acdId: "",
    acadText: "",
    startSession: "",
    endSession: "",
    reason: "",
    files: "",
  })
  const getLeaveTitle = async () => {
    try {
      await axios({
        methodL: "GET",
        url: "https://marcopolo.prisms.in/api/leave/get-leave-title",
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setTitle(res.data.LeaveTitles)
      })
    } catch (error) {}
  }

  const getLeaveBalance = async () => {
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/leave/get-leave-balance",
        data: {
          leaveType: data.leaveType,
          acadId: acadID.acadid,
          acadText: "",
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        console.log(res.data.leaveBalance)
        setLeaveCount(res.data.leaveBalance)
      })
    } catch (error) {}
  }

  useEffect(() => {
    getLeaveTitle()
  }, [])

  useEffect(() => {
    if (data.leaveType) {
      getLeaveBalance()
    }
  }, [data.leaveType])

  function handleChange(event) {
    const selectedValue = event.target.value
    const leaveData = JSON.parse(selectedValue)
    // setLeaveCount(leaveData.pending_leave)

    setData(prev => ({
      ...prev,
      leaveType: leaveData.lcat_id,
    }))
  }
  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
    setData(prev => ({
      ...prev,
      acdId: acadID.acadid,
    }))
  }, [])

  const handleSubmitMarks = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      if (
        !data.acdId &&
        !data.leaveType &&
        !data.startLeave &&
        !data.endLeave
      ) {
        toast.error("Please fill out all required fields.")
        setLoading(false)
        return
      }

      const fm = new FormData()

      fm.append("leaveType", data.leaveType)
      fm.append("acadText", data.acadText)
      fm.append("startLeave", data.startLeave)
      fm.append("endLeave", data.endLeave)
      fm.append("acadId", acadID.acadid)
      fm.append("startSession", data.startSession)
      fm.append("endSession", data.endSession)
      fm.append("reason", data.reason)
      fm.append("files", data.files)
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/leave/submit-leave",
        data: fm,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }).then(res => {
        toast.success(res.data.message)
        setLoading(false)
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
  }, []);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Container>
          <Card>
            <CardBody>
              <h1 className="text-center mt-2 mb-4 fs-4">Apply Leave</h1>
              {loading ? (
                <Spinners />
              ) : (
                <Row>
                  <Col xl="4" className="mt-1">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleChange}
                    >
                      <option>Select Title</option>

                      {title &&
                        title.length > 0 &&
                        title.map((leave, index) => (
                          <option
                            key={leave.lcat_id}
                            value={JSON.stringify({
                              lcat_id: leave.lcat_id,
                              lname: leave.lname,
                              pending_leave: leave.pending_leave,
                            })}
                          >
                            {leave.lname}
                          </option>
                        ))}
                    </select>
                  </Col>

                  <Col xl="4" className="mt-3 ">
                    <Label className="fw-medium text-dark ">
                      Leave Count : {leaveCount}{" "}
                    </Label>
                  </Col>

                  <Col xl="4" className="mt-2">
                    <Label className="">Start Date</Label>
                    <Input
                      type="date"
                      className=""
                      onChange={e =>
                        setData(prev => ({
                          ...prev,
                          startLeave: e.target.value,
                        }))
                      }
                    />
                  </Col>
                  <Col xl="4" className="mt-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={e =>
                        setData(prev => ({
                          ...prev,
                          startSession: e.target.value,
                        }))
                      }
                    >
                      <option>Select Session</option>
                      <option value={1}>Session 1</option>
                      <option value={2}>Session 2</option>
                    </select>
                  </Col>

                  <Col xl="4" className="mt-3">
                    <Label className="">End Date</Label>
                    <Input
                      type="date"
                      className=""
                      onChange={e =>
                        setData(prev => ({
                          ...prev,
                          endLeave: e.target.value,
                        }))
                      }
                    />{" "}
                  </Col>

                  <Col xl="4" className="mt-3">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={e =>
                        setData(prev => ({
                          ...prev,
                          endSession: e.target.value,
                        }))
                      }
                    >
                      <option value="default" className="text-danger">
                        Select Session
                      </option>

                      <option value={1}>Session 1</option>
                      <option value={2}>Session 2</option>
                    </select>
                  </Col>
                  <Col xl="4" className="mt-3">
                    <Label className="">Leave Reason</Label>
                    <Input
                      type="textarea"
                      onChange={e =>
                        setData(prev => ({
                          ...prev,
                          reason: e.target.value,
                        }))
                      }
                    />
                  </Col>
                  <Col xl="4" className="mt-3">
                    <Label>Attachment</Label>
                    <Input
                      type="file"
                      onChange={e =>
                        setData(prev => ({
                          ...prev,
                          files: e.target.files[0],
                        }))
                      }
                    />
                  </Col>

                  <Col xl="4" className="mt-3 flex">
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmitMarks}
                      >
                        Submit
                      </button>
                    </div>{" "}
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Container>
        <ToastContainer />
      </div>
    </div>
  )
}

export default ApplyLeave
