import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Comingsoon from "components/Common/CommingSoon"
import ExternalLinks from "pages/ExternalLinks"
import Notifications from "pages/Notifications/View"
import FeedBack from "pages/Feedback"
import CreateFeedback from "pages/Feedback/CreateFeedback"
import ChatWindow from "pages/Feedback/ChatWindow"
import MediaView from "components/Common/MediaView"
import HandleRoutes from "pages/HandleRoutes"
import CreateNotifications from "pages/Notifications/Create"
import ViewTimeTable from "pages/TimeTable/View"
import PaidFeesDetails from "pages/Fees/PaidFeesDetails"
import PrintRecipt from "pages/Fees/PaidFeesDetails/PrintRecipt"
import Attendance from "pages/Attendance/Teacher"
import Gallery from "pages/Gallery"
import ImageGallery from "pages/Gallery/ImageGallery"

// Exam Fill Marks for Teacher

import ExamFillMarker from "pages/TeacherApp/FillexamMarks/Home"
import ClassTestMarks from "pages/TeacherApp/ClassTestMarks/Card"
import SingleClassTest from "pages/TeacherApp/ClassTestMarks/MarksOfStud"
// Access Subject Report

import AccessHome from "pages/TeacherApp/SubjectAccess/SubjectHome"
import SubjectAccess from "pages/TeacherApp/SubjectAccess/Home"
import SubjectAccessReport from "pages/TeacherApp/SubjectAccess/Report"

// TEACHER APP - Routes
// Leave Apply for Teacher
import LeaveRequest from "pages/TeacherApp/LeaveApp/ApplyLeave"
import LeaveHistory from "pages/TeacherApp/LeaveApp/LeaveReport"
import StudentLeave from "pages/Report/LeaveReport"
// Teacher Pofile App
import TeacherProfile from "pages/TeacherApp/TeacherProfile/Home"

// Visitor App
import VisitorApp from "pages/TeacherApp/Visitor/Home"
import VisiorManullay from "pages/TeacherApp/Visitor/Manullay"
import Visitors from "pages/TeacherApp/Visitor/Checkout"
import VisitorScanner from "pages/TeacherApp/Visitor/Scanner"

import CheckoutHome from "pages/TeacherApp/Visitor/CheckoutHome"
// Visitor App
import AttendanceReport from "pages/Attendance/Teacher/AttendanceReport"

// Calendar App

import CalendarHome from "pages/TeacherApp/Calendar/Home"

// STUDENT PROFILE :
import StudentLeaveReport from "pages/STUDENT/StudentLeave/LeaveReport"
import StudentProfile from "pages/TeacherApp/StudentProfile/Home"
import StudentProfileApp from "pages/STUDENT/ProfileApp/Home"

import StudentPortfolioReport from "pages/STUDENT/Portfolio/Student/Home";
import StudentPortfolioDownload from "pages/STUDENT/Portfolio/Student/Report"

// Report Card

// Assignment
import AssigmentHome from "pages/TeacherApp/Assignment/Home"
import CreateAssignment from "pages/TeacherApp/Assignment/CreateAssignment"
import AssignmentReport from "pages/TeacherApp/Assignment/Report"
import ViewAssignmentStundent from "pages/TeacherApp/Assignment/ViewSingleAssingment"
import ReportCard from "pages/STUDENT/ReportCard/Home"
import ReportResult from "pages/STUDENT/ReportCard/ReportCard"
// Fees :
import DownloadFees from "pages/Fees/PaidFeesDetails/DownloadRecipt"
import ClassTestReport from "pages/STUDENT/ClassTest/Home";

import Fees from "pages/TeacherApp/FeesReport/Home";

import FeesDetails from "pages/TeacherApp/FeesReport/Report";


// Gallery

import GalleryStud from "pages/STUDENT/GalleryApp/Home";
import ListOfImages from "pages/STUDENT/GalleryApp/ImageList";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/dashboard/parent", component: <Dashboard /> },
  { path: "/dashboard/teacher", component: <Dashboard /> },
  { path: "/dashboard/admin", component: <Dashboard /> },

  //Utilities
  { path: "/external-link", component: <ExternalLinks /> },
  { path: "/media-preview", component: <MediaView /> },
  { path: "/handleroutes", component: <HandleRoutes /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/notifications", component: <Notifications /> },
  { path: "/create-notifications", component: <CreateNotifications /> },
  { path: "/feedback", component: <FeedBack /> },
  { path: "/feedback/create", component: <CreateFeedback /> },
  { path: "/feedback/chats", component: <ChatWindow /> },
  { path: "/timetable", component: <ViewTimeTable /> },

  { path: "/paidfeesdetails", component: <PaidFeesDetails /> },
  { path: "/printpaidfeesdetails", component: <PrintRecipt /> },

  { path: "/report/card", component: <ReportCard /> },
  
  { path: "/student-portfolio/report", component: <StudentPortfolioReport /> },
  { path: "/student/Leave-report", component: <StudentLeaveReport /> },

  { path: "/teacher/attendance", component: <Attendance /> },
  { path: "/teacher/attendance-report", component: <AttendanceReport /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/gallery/images", component: <ImageGallery /> },

  { path: "/student/gallery", component: <GalleryStud/> },
  { path: "/gallery-images/:id", component: <ListOfImages/> },

  { path: "/teacher/fill/marks", component: <ExamFillMarker /> },

  { path: "/class-test/marks", component: <ClassTestMarks /> },
  { path: "/class-test/marks/:id", component: <SingleClassTest /> },

  { path: "/subject-access-request", component: <AccessHome /> },
  { path: "/subject-access", component: <SubjectAccess /> },
  { path: "/subject-access-report", component: <SubjectAccessReport /> },

  { path: "/staff-profile", component: <TeacherProfile /> },
  { path: "/student-leave-report", component: <StudentLeave /> },
  { path: "/student-profile/pic", component: <StudentProfile /> },
  { path: "/student-profile", component: <StudentProfileApp /> },
  { path: "/fees", component: <Fees /> },
  { path: "/fees/details/:user_id/:id", component: <FeesDetails /> },

  { path: "/apply-leave", component: <LeaveRequest /> },
  { path: "/leave-history", component: <LeaveHistory /> },
  { path: "/visitor-home", component: <VisitorApp /> },
  { path: "/visitor-manually", component: <VisiorManullay /> },
  { path: "/visitors", component: <CheckoutHome /> },
  { path: "/calendar", component: <CalendarHome /> },
  { path: "/Assignment/home", component: <AssigmentHome /> },
  { path: "/create-assignment", component: <CreateAssignment /> },
  { path: "/assignment-report", component: <AssignmentReport /> },
  { path: "/view-assignment/:id", component: <ViewAssignmentStundent /> },
  { path: "/class-test", component: <ClassTestReport /> },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "*",
    component: <Comingsoon />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/visitor-scanner", component: <VisitorScanner /> },
  { path: "/report/card/details", component: <ReportResult /> },
  { path: "/feesdetails", component: <FeesDetails /> },

  { path: "/report", component: <StudentPortfolioDownload /> },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
