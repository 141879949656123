import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"

const ResuableComponent = ({ data }) => {
  console.log(data)
  // const statusClass =
  //   status === "PENDING"
  //     ? "text-warning"
  //     : status === "APPROVED"
  //     ? "text-success"
  //     : ""
  return (
    <div>
      {data &&
        data.map((item, index) => {
          const statusClass =
            item.status === "0"
              ? "text-warning"
              : item.status === "1"
              ? "text-success"
              : ""

          return (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                marginTop: "5px",
                padding: "10px",
                position: "relative",
              }}
            >
              <div className="d-flex flex-row justify-content-between">
                <span style={{ fontWeight: 600 }} className="mt-3 w-50">
                  Class:
                </span>
                <span className="mt-3 w-50 text-md-end">{item.div_name}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span style={{ fontWeight: 600 }} className="mt-3 w-50">
                  Subject:
                </span>
                <span className="mt-3 w-50 text-md-end">{item.subjects}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span style={{ fontWeight: 600 }} className="mt-3 w-50">
                  Date:
                </span>
                <span className="mt-3 w-50 text-md-end">
                  {moment(item.created_date).format("DD-MM-YYYY")}
                </span>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <span style={{ fontWeight: 600 }} className="mt-3 w-50">
                  Status:
                </span>
                <button
                  className={`mt-3 text-md-end fw-medium ${
                    item.status === 0 ? "btn btn-warning" : "btn btn-success"
                  }`}
                >
                  {item.status === 0 ? "Pending" : "Approved"}
                </button>
              </div>
            </div>
          )
        })}
    </div>
  )
}
const Report = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"))

  const getSubjectAccessReport = async () => {
    setLoading(true)
    try {
      await axios({
        method: "GET",
        url: "https://marcopolo.prisms.in/api/teacher-subjectAccess/getAllSubjectAccessRequests",

        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.error("Error fetching leave data:", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubjectAccessReport()
  }, [])

  return (
    <>
      <div className="page-content">
        {loading ? <Spinners /> : <ResuableComponent data={data || []} />}
      </div>
    </>
  )
}

export default Report
