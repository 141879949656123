import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentAcadid } from "store/Common/Actions"
import { getAttendanceDiv } from "store/Attendance/action"
import {
  getSubjectListStart,
  SubmitAccessRequestStart,
} from "store/SubjectRequest/action"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import DeleteModal from "./Modal"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const Home = () => {
  const dispatch = useDispatch()
  const Navigate = useNavigate()
  const { acadID } = useSelector(state => state.CommonReducer)
  const { divisions } = useSelector(state => state.AttendanceReducer)
  const { subjectList } = useSelector(state => state.SubjectRequest)

  const [selected, setSelected] = useState(true)
  const [div_id, setDivId] = useState(null)
  const [dmodel, setDmodel] = useState(false)
  const [divName, setDivName] = useState("")
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([])

  const [postData, setPostData] = useState({
    acadId: acadID.acadid,
    classDivId: div_id,
    subjecId: selectedSubjectIds,
  })

  const handleDivisionChange = e => {
    const selectedDivision = JSON.parse(e.target.value)
    setDivName(selectedDivision.name)
    setDivId(selectedDivision.id)
    setDmodel(true)
  }

  const onClickYes = () => {
    setSelected(true)
    setDmodel(false)
  }

  const onCloseClick = () => {
    setSelected(false)
    setDmodel(false)
  }

  useEffect(() => {
    if (!div_id) return
    dispatch(
      getSubjectListStart({
        acadId: acadID.acadid,
        classDivId: div_id,
      })
    )
  }, [acadID.acadid, div_id])

  const handleCheckboxChange = subid => {
    setSelectedSubjectIds(prev =>
      prev.includes(subid) ? prev.filter(id => id !== subid) : [...prev, subid]
    )
  }

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
    dispatch(getAttendanceDiv())
  }, [dispatch])

  useEffect(() => {
    setPostData({
      acadId: acadID.acadid,
      classDivId: div_id,
      subjectId: selectedSubjectIds,
    })
  }, [acadID.acadid, div_id, selectedSubjectIds])

  const handleSubmitRequest = async () => {
    if (!postData) return
    try {
      const apiToken = JSON.parse(localStorage.getItem("Token"))
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: apiToken,
        Accept: "application/json",
        "Cache-Control": "no-cache",
      }

      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_LIVE_API_URL}/api/teacher-subjectAccess/requestSubjectAccess`,
        data: postData,
        headers: {
          Authorization: apiToken,
        },
      })

      console.log(response.data)
      setSubjects(response.data)
      
    } catch (error) {
      console.error("Error fetching subject list:", error)
    }
  }

  return (
    <>
      <DeleteModal
        show={dmodel}
        onCloseClick={onCloseClick}
        onClickYes={onClickYes}
        division={divName}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Container>
            <Card>
              <CardBody>
                <Row>
                  <Col xl="4" className="mt-1">
                    <Label>Academic Year</Label>
                    <select className="form-select" disabled>
                      <option value="">{acadID?.academics}</option>
                    </select>
                  </Col>
                  <Col xl="4" className="mt-1">
                    <Label>Select Class</Label>
                    <select
                      className="form-select"
                      onChange={handleDivisionChange}
                    >
                      <option value="">Select Divisions</option>
                      {divisions?.map(classDiv => (
                        <option
                          key={classDiv.id}
                          value={JSON.stringify({
                            id: classDiv.id,
                            name: classDiv.div_name,
                          })}
                        >
                          {classDiv.div_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  {selected && (
                    <Col xl="4" className="mt-2">
                      {subjectList.length > 0 && <Label>Select Subjects</Label>}
                      {subjectList.map(sub => (
                        <div key={sub.id}>
                          <input
                            type="checkbox"
                            id={sub.id}
                            className="form-check-input"
                            onChange={() => handleCheckboxChange(sub.id)}
                            checked={selectedSubjectIds.includes(sub.id)}
                          />
                          <Label
                            htmlFor={sub.id}
                            check
                            className="form-check-label"
                          >
                            {sub.subject}
                          </Label>
                        </div>
                      ))}
                    </Col>
                  )}
                </Row>

                <div className="d-flex mt-4 align-items-center justify-content-center">
                  <button
                    onClick={handleSubmitRequest}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Home
