export const VIEW_PAIDFEES_DETAILS = "VIEW_PAIDFEES_DETAILS"
export const VIEW_PAIDFEES_DETAILS_SUCCESS = "VIEW_PAIDFEES_DETAILS_SUCCESS"
export const VIEW_PAIDFEES_DETAILS_FAILED = "VIEW_PAIDFEES_DETAILS_FAILED"

export const PRINT_PAIDFEES_DETAILS = "PRINT_PAIDFEES_DETAILS"
export const PRINT_PAIDFEES_DETAILS_SUCCESS = "PRINT_PAIDFEES_DETAILS_SUCCESS"
export const PRINT_PAIDFEES_DETAILS_FAILED = "PRINT_PAIDFEES_DETAILS_FAILED"


// FEES DETAILS FOR TEACHER -

export const GET_FEES_RECORD_START = "GET_FEES_RECORD_START"
export const GET_FEES_RECORD_SUCCESS = "GET_FEES_RECORD_SUCCESS"
export const GET_FEES_RECORD_ERROR = "GET_FEES_RECORD_ERROR"
export const GET_FEES_RECORD_RESET = "GET_FEES_RECORD_RESET"
