import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { useDispatch } from "react-redux";
import { getProfileDetail } from "store/actions";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);

  const selectProfileState = (state) => state.Profile;
  const ProfileProperties = createSelector(
    selectProfileState,
    (profile) => ({
      error: profile.error,
      success: profile.success,
      profileDetails: profile.profileDetails
    })
  );

  const {
    error,
    success,
    profileDetails
  } = useSelector(ProfileProperties);

  const [username, setusername] = useState("user");
  const [schoolDetails, setSchoolDetails] = useState({
    sid: null,
    uid: null
  })

  const [groupID, setGroupID] = useState("")

  useEffect(() => {
    if (profileDetails && profileDetails.name !== null) {
      // setusername(profileDetails?.name?.split(' ')[0])
      setusername(profileDetails.name ? profileDetails.name : "")

    }

  }, [profileDetails])

  useEffect(() => {
    const groupId = localStorage.getItem("group_id");
    if (groupId) {
      console.log(groupId)
      setGroupID(groupId);
    }
  }, []);


  useEffect(() => {
    dispatch(getProfileDetail())
    setSchoolDetails({
      ...schoolDetails,
      sid: localStorage.getItem("sid"),
      uid: localStorage.getItem("user_id")
    })
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {
            profileDetails && profileDetails?.avatar && profileDetails?.avatar !== null ?
              <img
                className="rounded-circle header-profile-user"
                src={profileDetails.avatar}
                alt={username}
              /> :

              <i className="bx bx-user rounded-circle font-size-22 align-middle" />
          }
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            {" "}
            {
              groupID && groupID == 2 ?
                <Link
                  to={"/student-profile"}
                >
                  {
                    profileDetails && profileDetails?.avatar && profileDetails?.avatar !== null ?
                      <img
                        className="rounded-circle align-middle me-1"
                        style={{ width: "35px", height: "35px" }}
                        src={profileDetails.avatar}
                        alt={username}
                      /> :

                      <i className="bx bx-user font-size-16 align-middle me-1" />
                  }
                  {/* <i className="bx bx-user font-size-16 align-middle me-1" /> */}
                  {props.t(username)}
                </Link>
                :

                <Link
                  to={"/profile"}
                >
                 {
                    profileDetails && profileDetails?.avatar && profileDetails?.avatar !== null ?
                      <img
                        className="rounded-circle align-middle me-1"
                        style={{ width: "20px", height: "20px" }}
                        src={profileDetails.avatar}
                        alt={username}
                      /> :

                      <i className="bx bx-user font-size-16 align-middle me-1" />
                  }
                  {props.t(username)}
                </Link>

            }
            {" "}
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Switch User")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
