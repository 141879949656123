import React, { useState, useEffect } from "react"
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Table,
  Button,
  Col,
  Row,
  Spinner,
  Card,
  CardBody,
} from "reactstrap"
import * as Yup from "yup"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { getAttendanceDiv, getCurrentAcadid } from "store/actions"
import { getSubjectListStart } from "store/SubjectRequest/action"
import Spinners from "components/Common/Spinner"
import { toast, ToastContainer } from "react-toastify"
import moment from "moment"
const CreateAssignment = () => {
  const dispatch = useDispatch()
  const token = JSON.parse(localStorage.getItem("Token"))
  const { divisions } = useSelector(state => state.AttendanceReducer)
  const { subjectList } = useSelector(state => state.SubjectRequest)
  const { acadID } = useSelector(state => state.CommonReducer)
  const [Loading, setLoading] = useState(false)

  const [date, setDate] = useState("")
  const [assigmentData, setAssignmentData] = useState({
    acad_id: "",
    division_id: "",
    subject_id: "",
    date: "",
    name: "",
    description: "",
    web: 0,
    app: 0,
    email: 0,
    sender_ip_address: "",
    files: "",
  })
  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")
    const day = String(currentDate.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    setDate(formattedDate)
  }, [])

  const [checkBox, setCheckBox] = useState({
    web: 0,
    app: 0,
    email: 0,
    // smsCheck: 0,
  })

  const [div_id, setDivId] = useState(null)

  const handleCheckBox = inputCheck => {
    setAssignmentData({
      ...assigmentData,
      [inputCheck.target.name]: inputCheck.target.checked ? 1 : 0,
    })
  }

  const handleInputChange = e => {
    setDivId(e.target.value)
  }

  useEffect(() => {
    dispatch(getAttendanceDiv())
    // const getClassDiv = async () => {
    //   try {
    //     await axios({
    //       method: "POST",
    //       url: "https://marcopolo.prisms.in/api/examfillmarks/get-classdiv-list",
    //       data: {
    //         acad_id: acadID.acadid,
    //       },
    //       headers: {
    //         Authorization: token,
    //       },
    //     }).then(res => {
    //       setDiv(res.data.data)
    //     })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // getClassDiv()
  }, [])

  useEffect(() => {
    if (localStorage.getItem("sid")) {
      dispatch(
        getCurrentAcadid({
          sid: localStorage.getItem("sid"),
        })
      )
    }
  }, [])

  useEffect(() => {
    if (!div_id) return
    dispatch(
      getSubjectListStart({
        acadId: acadID.acadid,
        classDivId: div_id,
      })
    )

    setAssignmentData(prev => {
      return {
        ...prev,
        division_id: div_id,
        acad_id: acadID.acadid,
      }
    })
  }, [acadID.acadid, div_id])

  const handleSubmitAssignment = async e => {
    e.preventDefault()

    try {
      if (
        !assigmentData.division_id &&
        !assigmentData.subject_id &&
        !assigmentData.name &&
        !assigmentData.description
      ) {
        toast.error("Please fill out all required fields.")
        return
      }
      setLoading(true)

      const fm = new FormData()

      fm.append("acad_id", assigmentData.acad_id)
      fm.append("division_id", assigmentData.division_id)
      fm.append("subject_id", assigmentData.subject_id)
      fm.append("date", moment(date).format("MM-DD-YYYY"))
      fm.append("name", assigmentData.name)
      fm.append("description", assigmentData.description)
      fm.append("web", assigmentData.web)
      fm.append("app", assigmentData.app)
      fm.append("email", assigmentData.email)
      fm.append("sender_ip_address", assigmentData.sender_ip_address)
      fm.append("files", assigmentData.files)

      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/Assignment/create-assignment",
        data: fm,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      }).then(res => {
        toast.success(res.data.message)
        setLoading(false)
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error("something went wrong")
    }
  }
  return (
    <div className="page-content px-0 bg-light">
      <Container>
        <Card className="shadow-lg rounded-lg">
          <CardBody>
            <Row>
              <Col xl="12">
                <h1 className="text-center mt-2 fs-4">Assignment</h1>

                <FormGroup>
                  <span className="text-dark fw-medium mb-2">Destination</span>

                  <div className="d-flex justify-content-between mt-2">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        name="web"
                        onClick={e => handleCheckBox(e)}
                        type="checkbox"
                        id="WebSelect"
                      />
                      <label className="form-check-label" htmlFor="WebSelect">
                        Web
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        name="app"
                        onClick={e => handleCheckBox(e)}
                        type="checkbox"
                        id="appSelect"
                      />
                      <label className="form-check-label" htmlFor="appSelect">
                        App
                      </label>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        name="email"
                        onClick={e => handleCheckBox(e)}
                        type="checkbox"
                        id="emailSelect"
                      />
                      <label className="form-check-label" htmlFor="emailSelect">
                        Email
                      </label>
                    </div>
                  </div>
                </FormGroup>
              </Col>

              <Col xl="4" className="mt-2">
                <FormGroup>
                  <Label
                    for="Selectteachers"
                    className="font-weight-bold text-dark"
                  >
                    Select Division
                  </Label>
                  <select
                    id="Selectteachers"
                    name="classdivid"
                    className="form-control"
                    onChange={handleInputChange}
                  >
                    <option value="">Select Divisions</option>
                    {divisions?.map(classDiv => (
                      <option key={classDiv.id} value={classDiv.id}>
                        {classDiv.div_name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>

              <Col xl="4" className="mt-1">
                <FormGroup>
                  <Label
                    for="selectSubject"
                    className="font-weight-bold text-dark"
                  >
                    Select Subject
                  </Label>
                  <select
                    className="form-control"
                    id="selectSubject"
                    onChange={e =>
                      setAssignmentData(prev => ({
                        ...prev,
                        subject_id: e.target.value,
                      }))
                    }
                  >
                    <option value="">Select Subject</option>
                    {subjectList &&
                      subjectList.map((sub, index) => (
                        <option value={sub.id} key={index}>
                          {sub.subject}
                        </option>
                      ))}
                  </select>
                </FormGroup>
              </Col>

              <Col xl="4" className="mt-1">
                <FormGroup>
                  <Label for="dateInput" className="font-weight-bold text-dark">
                    Date
                  </Label>
                  <Input
                    type="date"
                    id="dateInput"
                    value={date}
                    onChange={e => setDate(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col xl="4" className="mt-1">
                <FormGroup>
                  <Label for="nameInput" className="font-weight-bold text-dark">
                    Name
                  </Label>
                  <Input
                    type="text"
                    id="nameInput"
                    onChange={e =>
                      setAssignmentData(prev => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </FormGroup>
              </Col>

              <Col xl="4" className="mt-1">
                <FormGroup>
                  <Label
                    for="descriptionInput"
                    className="font-weight-bold text-dark"
                  >
                    Description
                  </Label>
                  <Input
                    type="textarea"
                    id="descriptionInput"
                    onChange={e =>
                      setAssignmentData(prev => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                </FormGroup>
              </Col>

              <Col xl="4" className="mt-1">
                <FormGroup>
                  <Input
                    type="file"
                    id="fileInput"
                    onChange={e =>
                      setAssignmentData(prev => ({
                        ...prev,
                        files: e.target.files[0],
                      }))
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="text-center mt-1">
              <Button
                color="primary"
                className="px-4 py-2"
                onClick={handleSubmitAssignment}
              >
                Create Assignment
              </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
      <ToastContainer />
    </div>
  )
}

export default CreateAssignment
