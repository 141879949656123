import React, { useState, useEffect } from "react"
import axios from "axios"
import Spinners from "components/Common/Spinner"
import moment from "moment/moment"
import { useParams } from "react-router-dom"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

const ResuableComponent = ({ data }) => {
  console.log(data)
  return (
    <div>
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                marginTop: "5px",
                padding: "10px",
                position: "relative",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="w-100">
                <span className="mt-3 text-start">{item.rcp_no}</span>

                  <div className="d-flex flex-row justify-content-around align-items-center item">
                    <span className="mt-3 text-start">{item.category}</span>
                    <span className="mt-1 text-start">{item.amount}</span>
                  </div>
                </div>
                {/* <div className="w-30 mt-4 text-center">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                

                
                  </div>
                </div> */}
              </div>
            </div>
          )
        })}
    </div>
  )
}
const Report = () => {
  const { user_id, id } = useParams()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const token = JSON.parse(localStorage.getItem("Token"))

  const getFeesHistory = async () => {
    setLoading(true)
    try {
      await axios({
        method: "POST",
        url: "https://marcopolo.prisms.in/api/FeesReport/get-paid-fees-history",
        data: {
          student_id: user_id,
          package_id: id,
        },
        headers: {
          Authorization: token,
        },
      }).then(res => {
        setData(res.data.data.data)
        setLoading(false)
      })
    } catch (error) {
      console.error(error.message)
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    getFeesHistory()
  }, [])

  if (error) {
    return (
      <div className="page-content">
        <h1 className="text-center text-danger mt-2 mb-4 fs-4">
          Oops something went wrong!
        </h1>
      </div>
    )
  }
  return (
    <>
      <div className="page-content">
        {/* <h1 className="text-center mt-2 mb-4 fs-4">Fees</h1> */}

        {loading ? <Spinners /> : <ResuableComponent data={data || []} />}
      </div>
    </>
  )
}

export default Report
